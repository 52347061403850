<template>

  <div class="row" ref="roleForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Name"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>
            <div class="form-group">
              <label>Permissions</label>
              <el-select multiple class="select-info"
                         size="large"
                         v-model="formData.permissions"
                         filterable
                         placeholder="Select Permissions">
                <el-option v-for="option in permissions"
                           class="select-info"
                           :value="option.id"
                           :label="option.title"
                           :key="option.code">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;
            <l-button @click="$router.push('/roles/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        permissions: []
      },

      permissions: [],
    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.roleForm
    });

    this.axios.get("roles/builder").then((response) => {
      this.permissions = response.data.permissions;
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {

        this.editMode = true;
        this.formTitle = "Edit Role";
        this.getRole();

      } else {
        this.editMode = false;
        this.formTitle = "Add Role";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {


    getRole() {
      this.axios.get("roles/get/" + this.id).then((response) => {
        this.formData.name = response.data.name;
        this.formData.permissions = response.data.permissions.map(p => {
          return p.id
        });

        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Role Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("roles/update/" + this.id, this.formData);
        successMessage = "Role Updated Successfully";
      } else {
        request = this.axios.post("roles/create", this.formData);
        successMessage = "Role Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/roles/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    }

  }
}
</script>

<style>
</style>
